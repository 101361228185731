<template>
  <div class="w-100">
    <div
      class="d-flex flex-wrap flex-gap-md justify-content-start justify-content-md-center"
    >
      <a
        v-for="socialMedia in socialMedias"
        :key="socialMedia.key"
        class="footer-social-media"
        target="_blank"
        :href="socialMedia.url"
      >
        <feather-icon
          :icon="socialMedia.icon"
          class="text-dark"
          size="20"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    socialMedias: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
