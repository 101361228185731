/* eslint-disable prefer-destructuring */ /* eslint-disable no-trailing-spaces
*/ /* eslint-disable no-restricted-syntax */
<template>
  <!-- :style="accountancy_event ? 'background-color: #41a7ae;' : ''" -->
  <!-- :class="{ 'bg-primary' : accountancy_event }" -->
  <div
    id="div-with-loading"
    class="listings-event w-100 text-pantone-445"
  >
    <CeaseBanner />

    <AppNavbarFull
      v-if="showLayout"
      :company-image="group_event_image"
    />

    <b-row v-if="specific_company">
      <b-card
        v-if="specific_company_image"
        class="w-100 mx-auto"
      >
        <div class="">
          <h3>All events by :</h3>
        </div>

        <div class="">
          <img
            :src="`${$bucketUrl}/company-profile-image/${specific_company_image}`"
            alt="content-img"
            class="image-size mx-auto self-center"
          >
        </div>
      </b-card>
    </b-row>

    <div
      v-if="false"
      class="banner-container d-flex flex-wrap align-items-center jusitfy-content-center w-100"
      :class="{ 'bg-primary-gradient' : accountancy_event }"
    >
      <div class="w-100 d-flex flex-wrap align-items-center justify-content-between">
        <div class="">
          <img
            :src="logoImage"
            style="height: 4rem"
            class="w-auto object-scale-down"
          >
        </div>
        <div
          class="d-flex flex-row"
          style="gap: 3rem;"
        >
          <router-link
            class="text-white"
            :to="{name: 'profile'}"
          >
            Home
          </router-link>
          <router-link
            v-if="!isLoggedIn"
            class="text-white"
            :to="{name: 'auth-login'}"
          >
            Sign In
          </router-link>
          <!-- <router-link class="text-white" :to="{name: 'profile'}">Home</router-link>
          <router-link class="text-white" :to="{name: 'profile'}">Home</router-link> -->
        </div>
      </div>

      <div class="w-50">
        <h1 class="text-light">
          <b class="big-title very-bold"> Design Jobs </b>
          <br>
          <span class="big-title less-bold"> Careers Hub </span>
        </h1>

        <hr
          style="width: 35ch;"
          class="thick-separator"
        >

        <h1 class="text-light">
          <b class="medium-title very-bold"> Events </b>
        </h1>
      </div>
      <div
        class="d-flex justify-content-center w-50"
        :class="{'mt-4 mb-4': ['listings-page', 'listings-event'].includes($route.name)}"
      >
        <img
          v-if="['listings-page', 'listings-event'].includes($route.name)"
          :src="
            `${group_event_image}`
          "
          alt="content-img"
          class="image-size mx-auto"
          @click="checkRedirection"
        >
      </div>
    </div>

    <div
      class="main-section banner-container d-flex flex-wrap jusitfy-content-center w-100"
      :class="{ 'bg-main-background' : accountancy_event }"
    >
      <div
        v-if="false"
        class="w-100 d-flex flex-row align-items-center justify-content-between"
      >
        <div
          class=""
        >
          <img
            :src="logoImage"
            style="height: 4rem"
            class="w-auto object-scale-down"
          >
        </div>

        <div
          class="d-md-flex d-none flex-row text-din-bold"
          style="gap: 3rem;"
        >
          <router-link
            class="text-dark"
            :to="{name: 'profile'}"
          >
            Home
          </router-link>

          <a
            class="text-dark"
            href="mailto:talent@designsingapore.org"
          >
            Contact Us
          </a>

          <router-link
            v-if="!isLoggedIn"
            class="text-dark"
            :to="{name: 'auth-login'}"
          >
            Sign In
          </router-link>
          <!-- <router-link class="text-white" :to="{name: 'profile'}">Home</router-link>
          <router-link class="text-white" :to="{name: 'profile'}">Home</router-link> -->
        </div>

        <div
          class="d-md-none d-block"
        >
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            class="mobile-nav-dropdown text-right"
            no-caret
            right
          >
            <template #button-content>
              <div>
                <feather-icon
                  icon="MenuIcon"
                  size="32"
                />
              </div>
            </template>
            <b-dropdown-item href="#">
              <router-link
                class="text-dark"
                :to="{name: 'profile'}"
              >
                Home
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item
              target="_blank"
              href="https://designsingapore.org/contact-us/"
            >
              <a
                class="text-dark"
                href="mailto:talent@designsingapore.org"
              >
                Contact Us
              </a>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!isLoggedIn"
              href="#"
            >
              <router-link
                class="text-dark"
                :to="{name: 'auth-login'}"
              >
                Sign In
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <b-row class="m-0 p-0 w-100 d-flex align-items-center">
        <b-col
          cols="12"
          md="4"
          class="p-0 mt-0 mt-md-0 mb-3 mb-md-0"
        >
          <div class="d-flex d-md-none">
            <img
              v-if="['listings-page', 'listings-event'].includes($route.name)"
              :src="
                `${group_event_image}`
              "
              alt="content-img"
              class="image-size mx-auto"
              @click="checkRedirection"
            >
          </div>

          <p
            class="mt-1 mt-md-0 mb-0 big-title text-din-black text-pantone-445"
            style="line-height: 3.5rem;"
          >
            <span class="text-primary">Design </span>
            <span class="">Talent </span> <br>
            <span class="">Marketplace </span>
          </p>

          <div
            class="d-flex"
            style="gap: 2rem;"
          >
            <div class="w-50 py-1">
              <div class="dsg-h-text">
                <p class="m-0 p-0 text-din-bold line-height-single">
                  Unlock Your Potential
                </p>

              </div>

              <b-button
                class="text-din-bold"
                pill
                variant="primary"
                @click="$router.push('/listings')"
              > <b class="text-white">Individual</b> </b-button>
            </div>

            <div class="w-50 py-1">
              <div class="dsg-h-text">
                <p class="m-0 p-0 text-din-bold line-height-single">
                  Find Your Designers
                </p>

              </div>

              <b-button
                class="text-din-bold"
                pill
                variant="primary"
                @click="$router.push('/')"
              > <b class="text-white">Employer</b> </b-button>
            </div>
          </div>
        </b-col>

        <!-- :class="{'mt-2 mb-2': ['listings-page', 'listings-event'].includes($route.name)}" -->

        <b-col
          cols="12"
          md="8"
          class="p-0 d-none d-md-flex justify-content-end"
        >
          <img
            v-if="['listings-page', 'listings-event'].includes($route.name)"
            :src="
              `${group_event_image}`
            "
            alt="content-img"
            class="image-size"
            @click="checkRedirection"
          >
        </b-col>
      </b-row>
    </div>

    <div class="banner-container mt-3 d-flex flex-wrap align-items-center justify-content-center">
      <div class="d-flex flex-column flex-md-row w-100">
        <!-- <b-col v-if="false" class="text-center">
          <img
            v-if="['listings-page', 'listings-event'].includes($route.name)"
            src="@/assets/images/dsg_career_portal.png"
            alt="content-img"
            class="image-size mx-auto"
            height="300"
            @click="checkRedirection"
          >
        </b-col> -->

        <div
          class="d-flex flex-column align-items-start w-100 w-md-50"
        >
          <hr
            class="d-none d-md-block thick-separator"
            style="width: 35ch; margin-top: 0 !important; margin-bottom: 2rem;"
          >
          <p class="text-extra-large text-din-black text-pantone-445">
            Welcome to the <br>
            <span class="text-primary">Design</span>
            Talent <br>
            Marketplace
          </p>
          <hr
            class="d-block d-md-none thick-separator"
            style="width: 25ch; margin-top: 0 !important; margin-bottom: 1.5rem;"
          >
        </div>

        <div
          class="d-flex align-items-start w-100 w-md-50"
        >
          <div
            class="description-container"
          >
            <p class="text-regular text-din-regular text-justify text-pantone-445">
              <!-- The Design Careers Hub (DCH) is an initiative by the DesignSingapore Council, which aims to be the main touchpoint providing employment and employability support for the design sector. The DCH offers targeted jobs, industry-specific events and resources to job seekers who are looking to enter the industry or in-employment workers exploring new career tracks within the design sector. -->
              <!-- Welcome to the Design Talent Marketplace (DTM) <br>
              Where design talent meets opportunities! <br> -->
              <!-- <br> -->
              Launched by the
              <a
                href="https://designsingapore.org/"
                target="_blank"
                class="text-primary"
              >
                <b>DesignSingapore Council,</b>
              </a>
              the Design Talent Marketplace is a dedicated design career facilitation portal that connects employers across different sectors with design students, graduates and working designers.<br>
              <br>
              Register now to discover the latest jobs, hunt for the right designers for your firms, plan your next career move and join community-led networking events.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="banner-container"
      style="padding-block: 1rem;"
    >
      <hr
        class="thick-separator"
        style="margin-top: 0 !important;"
      >
    </div>
    <div class="d-flex justify-content-center">
      <span class="text-large text-din-black">
        Featured Companies
      </span>
    </div>

    <div class="banner-container d-flex justify-content-center">
      <div
        id=""
        class="mx-3"
      >
        <div
          class="py-2 d-flex flex-wrap flex-md-row align-items-center justify-content-center justify-md-content-start"
          style="gap: 2rem"
        >
          <template
            v-for="ky in companyLogosRange"
          >
            <div
              v-if="companyLogos[ky -1]"
              :key="ky"
              class="d-flex align-items-center text-center"
              style="width: 100px; height: 100px"
            >
              <a :href="companyLogos[ky -1].url">
                <img
                  v-if="![ '10-second.webp','7-ong.png', '8-osd.png'].includes(companyLogos[ky -1].filename)"
                  :src="require('@/assets/images/pages/listings/companies/'+companyLogos[ky -1].filename)"
                  style="max-width: 100%;"
                  class="object-scale-down"
                >
                <img
                  v-else
                  :src="require('@/assets/images/pages/listings/companies/'+companyLogos[ky -1].filename)"
                  style="max-width: 100%;   filter: invert(100%);"
                  class="object-scale-down"
                >
              </a>
            </div>
          </template>

        </div>

        <div
          class="d-flex justify-content-center"
          style="gap: 0 1rem;"
        >
          <div
            v-for="index in Math.ceil(companyLogos.length / companyLogosQuantity)"
            :key="index.key"
            :class="`cursor-pointer ${companyLogosCurrentPage === index ? 'dot-primary': 'dot-grey'}`"
            @click="scrollCompanyLogos(index)"
          />
        </div>
      </div>
    </div>

    <div
      id="highlights"
      class="banner-container"
      style="padding-block: 1rem;"
    >
      <hr
        class="thick-separator"
        style="margin-top: 0 !important;"
      >
    </div>
    <div class="d-flex justify-content-center">
      <span class="text-large text-din-black">
        Highlights
      </span>
    </div>

    <div
      v-if="false"
      class="my-2 bg-white"
    >
      <div class="p-8">
        <div class="container-fluid px-4">
          <b-row class="w-100 mx-auto">
            <h4
              class="mx-auto"
              :class="accountancy_event ? 'text-white' : 'text-dark'"
            >
              Search by Job Title
            </h4>
          </b-row>
          <b-row class="">
            <b-input-group class="">
              <b-form-input
                v-model="selected_event_title"
                class="w-auto"
                :class="{'w-75': isMobile}"
                placeholder="Start typing to search for a specific job title"
                @keyup="searchText"
              />

              <b-input-group-append
                :class="{'w-25': isMobile}"
              >
                <b-button
                  variant="primary"
                  class="w-100"
                  @click="specificGetEvents()"
                >
                  <feather-icon
                    class="mr-md-1"
                    icon="SearchIcon"
                    size="15"
                  />
                  <span v-if="!isMobile">Search</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-row>

          <b-modal
            id="modal-lg"
            ref="share-job-modal"
            centered
            hide-footer
            title="Share Job"
          >
            <div class="d-flex flex-column">
              <h6 class="mx-auto">
                The link that will be shared.
              </h6>
              <b-form-input
                v-model="share_link"
                class="mt-1 mx-auto"
              />
            </div>
            <div class="d-flex flex-wrap justify-content-center p-2">
              <ShareNetwork
                v-for="(info, index) of all_share_icons"
                :key="index"
                class="mx-1"
                :network="info.network"
                :url="getLink(current_item.id)"
                :title="
                  'Check out this ' +
                    current_item.position +
                    ' event organised by ' +
                    current_item.company_name +
                    ' via HeadHunters HQ.'
                "
              >
                <font-awesome-icon
                  :icon="info.name"
                  size="3x"
                  :class="info.styling"
                />
              </ShareNetwork>
            </div>
          </b-modal>
        </div>

        <b-modal
          id="modal-lg"
          ref="filter-job-modal"
          centered
          hide-footer
          title="Job Type"
        >
          <div class="mt-1 mb-1">
            <v-select
              v-model="selected_job_type"
              placeholder="Choose your preferred job type"
              :options="job_types"
              label="text"
              multiple
              class=""
              :reduce="(value) => value.value"
            />
          </div>
        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-position-modal"
          centered
          hide-footer
          title="Employment Type"
        >
          <div
            class="mt-1 mb-1"
          >
            <v-select
              v-model="selected_industry"
              placeholder="Choose your preferred employer industry"
              multiple
              label="value"
              :options="industries"
              class=""
              :reduce="(value) => value.value"
            />
          </div>
        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-employer-modal"
          centered
          hide-footer
          title="Employer Name"
        >
          <div>
            <v-select
              v-model="select_company"
              placeholder="Choose an employer"
              multiple
              label="value"
              class=""
              :options="companies"
              :reduce="(value) => value.value"
            />
          </div>

        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-work-modal"
          centered
          hide-footer
          title="Work Location"
        >
          <div>
            <v-select
              v-model="selected_location"
              placeholder="Choose your preferred work location"
              multiple
              label="value"
              :options="all_locations"
              class=""
              :reduce="(value) => value.value"
            />
          </div>

        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-salary-modal"
          centered
          hide-footer
          title="Salary Range"
        >
          <div>
            <h6 class="">
              Per Annum
            </h6>
            <vue-slider
              v-model="salary_range_yearly"
              :enable-cross="false"
              class="w-100 mt-3"
              :contained="true"
              :silent="true"
              :tooltip="'always'"
              :min="min_salary_range"
              :max="max_salary_range"
              :interval="1"
              :tooltip-formatter="salary_formatter"
            />
            <h6 class="">
              Per Monthly
            </h6>
            <vue-slider
              v-model="salary_range_monthly"
              :enable-cross="false"
              class="w-100 mt-3"
              :contained="true"
              :silent="true"
              :tooltip="'always'"
              :min="min_salary_range"
              :max="max_salary_range"
              :interval="1"
              :tooltip-formatter="salary_formatter"
            />
          </div>
        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-employment-modal"
          centered
          hide-footer
          title="Employment Type"
        >
          <div>
            <v-select
              v-model="selected_employment_type"
              placeholder="Choose your preferred employment type"
              multiple
              label="text"
              class=""
              :options="employment_types"
              :reduce="(value) => value.value"
            />
          </div>
        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-sort-modal"
          centered
          hide-footer
          title="Sort by"
        >

          <div>
            <h6 class="">
              By Position
            </h6>
            <v-select
              v-model="sort_choice"
              label="value"
              class=""
              :options="sort_options"
              :reduce="(value) => value.value"
            />
          </div>
        </b-modal>

        <div
          :class="{ 'invisible': !isMobile }"
          class="filter-button-container"
        >
          <div class="filter-button-box">
            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterEvent()"
            >
              <span class="align-middle"> Job Type</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterEmployer()"
            >
              <span class="align-middle">Employer Name</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterWork()"
            >
              <span class="align-middle"> Work Location</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterSalary()"
            >
              <span class="align-middle"> Salary Range</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterEmployment()"
            >
              <span class="align-middle"> Employment Type</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterSort()"
            >
              <span class="align-middle"> Sort By</span>
            </b-button>
          </div>
        </div>

        <div class="d-flex flex-column flex-sm-column flex-lg-row w-100">
          <!-- <div v-for="(group, i) in eventsGroups" :key="i"> -->
          <!-- .slice(i * 4, (i + 1) * 4) -->
          <b-col
            class=""
            cols="12"
            lg="4"
            sm="12"
          >
            <div class="p-8">
              <b-card :class="{'d-none' : isMobile}">
                <div class="w-100">
                  <div
                    class="d-flex flex-row flex-wrap mx-1"
                    :class="
                      expand_search
                        ? 'justify-content-between'
                        : 'justify-content-end'
                    "
                  >
                    <b-button
                      v-if="expand_search"
                      class="align-left"
                      :class="{'w-100': isMobile}"
                      style="width: 150px"
                      block
                      variant="outline-primary"
                      @click="resetFilters"
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        style="margin-right: 4px; margin-bottom: 1px"
                      />
                      <span class="align-middle">Reset Filters</span></b-button>

                    <b-button
                      class="btn-icon d-block d-lg-none"
                      :class="{'w-100': isMobile}"
                      variant="primary"
                      @click="expand_search = !expand_search"
                    >
                      <feather-icon
                        :icon="
                          expand_search ? 'Minimize2Icon' : 'Maximize2Icon'
                        "
                        class=""
                      />
                      <span class="align-middle">{{
                        expand_search ? "" : "Maximize Filters Panel"
                      }}</span>
                    </b-button>
                  </div>
                  <b-overlay
                    :show="searchLoading"
                    rounded="sm"
                  >
                    <b-form-group class="container-fluid">
                      <div
                        v-if="expand_search"
                        class="mt-2 mb-1"
                      >
                        <h6 class="">
                          Sort Positions By
                        </h6>

                        <v-select
                          v-model="sort_choice"
                          label="value"
                          class=""
                          :options="sort_options"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="companies.length > 0 && expand_search"
                        class="mt-1 mb-1"
                      >
                        <h6 class=" ">
                          Employer Name
                        </h6>
                        <v-select
                          v-model="select_company"
                          placeholder="Choose an employer"
                          multiple
                          label="value"
                          class=""
                          :options="companies"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        class="mt-1 mb-1"
                      >
                        <h6 class="">
                          Job Level
                        </h6>
                        <v-select
                          v-model="selected_job_level"
                          placeholder="Choose your preferred job level"
                          multiple
                          label="value"
                          :options="jobLevelOptions"
                          class=""
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="
                          !specific_company &&
                            organiser_companies.length > 0 &&
                            expand_search
                        "
                        class="mt-1 mb-1"
                      >
                        <h6 class="">
                          Event Organiser Name
                        </h6>
                        <v-select
                          v-model="select_organiser"
                          placeholder="Choose an event organiser"
                          :options="organiser_companies"
                          multiple
                          label="value"
                          class=""
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="all_locations.length > 0 && expand_search"
                        class="mt-1 mb-1"
                      >
                        <h6 class="">
                          Work Location
                        </h6>
                        <v-select
                          v-model="selected_location"
                          placeholder="Choose your preferred work location"
                          multiple
                          label="value"
                          :options="all_locations"
                          class=""
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="expand_search"
                        class="mt-1 mb-1"
                      >
                        <h6 class="">
                          Salary Range (Per Annum)
                        </h6>
                        <vue-slider
                          v-model="salary_range"
                          :enable-cross="false"
                          class="w-100 mt-3 cursor-pointer"
                          :contained="true"
                          :silent="true"
                          :tooltip="'always'"
                          :min="min_salary_range"
                          :max="max_salary_range"
                          :interval="1"
                          :tooltip-formatter="salary_formatter"
                        />
                      </div>

                      <div
                        v-if="job_types.length > 0 && expand_search"
                        class="mt-1 mb-1"
                      >
                        <h6 class="">
                          Job Type
                        </h6>
                        <v-select
                          v-model="selected_job_type"
                          placeholder="Choose your preferred job type"
                          :options="job_types"
                          label="text"
                          multiple
                          class=""
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="employment_types.length > 0 && expand_search"
                        class="mt-1 mb-1"
                      >
                        <h6 class="">
                          Employment Type
                        </h6>
                        <v-select
                          v-model="selected_employment_type"
                          placeholder="Choose your preferred employment type"
                          multiple
                          label="text"
                          class=""
                          :options="employment_types"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="industries.length > 0 && expand_search"
                        class="mt-1 mb-1"
                      >
                        <h6 class="">
                          Employer Industry
                        </h6>
                        <v-select
                          v-model="selected_industry"
                          placeholder="Choose your preferred employer industry"
                          multiple
                          label="value"
                          :options="industries"
                          class=""
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="event_id && all_job_functions.length > 0 && expand_search"
                        class="mt-1 mb-1"
                      >
                        <h6 class="">
                          Job Function
                        </h6>
                        <v-select
                          v-model="selected_job_function"
                          placeholder="Choose your preferred job function"
                          multiple
                          label="value"
                          :options="all_job_functions"
                          class=""
                          :reduce="(value) => value.value"
                        />
                      </div>
                      <div
                        v-if="
                          !specific_company &&
                            all_dates.length > 0 &&
                            expand_search
                        "
                        class="mt-1 mb-1"
                      >
                        <h6>Date Of Event</h6>
                        <v-select
                          v-model="select_date"
                          label="value"
                          placeholder="Choose the start date of the event"
                          :options="all_dates"
                          multiple
                          class=""
                          :reduce="(value) => value.value"
                        />
                      </div>
                    </b-form-group>
                  </b-overlay>
                </div>
              </b-card>
            </div>
          </b-col>

          <b-overlay
            :show="jobsLoading"
            rounded="sm"
            class="w-100"
            cols="12"
            lg="8"
            sm="12"
          >
            <b-col
              v-if="true"
              id="jobs_loading"
              class="w-100"
              sm="12"
            >
              <div
                v-for="(item, index) in events_data"
                :key="index"
                class="w-100"
              >
                <b-card class="d-flex flex-column w-100">
                  <b-badge
                    v-if="item.featured"
                    class="ml-1"
                    style="margin-top: -20px;"
                    variant="warning"
                  >
                    Featured
                  </b-badge>
                  <b-row class="w-100">
                    <b-col
                      lg="9"
                      sm="12"
                      class="mt-2"
                    >

                      <div class="w-100">
                        <div class="d-flex flex-wrap">
                          <h6
                            v-if="item.event_category === 'join'"
                            class="ml-1"
                          >
                            <span>{{ item.company_name }}</span>
                          </h6>
                          <h5
                            v-else
                            class="ml-1"
                          >
                            {{ item.company_name }}
                          </h5>

                        </div>

                        <div class="d-flex flex-wrap">
                          <h2
                            class="text-primary ml-1"
                            style="margin-left: 5px; font-weight: 400"
                          >
                            {{ item.position }}
                          </h2>

                        </div>
                        <!-- v-if="item.event_name" -->
                        <b-row
                          class="ml-1"
                        >
                          <p
                            v-if="item.organiser_name && !grouped()"
                            style="font-weight: 600; font-size: 14px"
                          >
                            <a
                              v-if="false"
                              :href="
                                base_listings + item.event_id + '_g'
                              "
                            >{{ item.event_name }}</a>
                            event
                            {{
                              cleanerIntervals(
                                item.cleaned_start_date,
                                item.cleaned_end_date
                              )
                            }}
                            ({{ item.cleaned_start_time }} to
                            {{ item.cleaned_end_time }})
                          </p>
                        </b-row>

                        <div
                          class="d-flex flex-wrap ml-1"
                          style="font-weight: 600; font-size: 14px"
                        >
                          <div
                            v-if="false"
                            class="d-flex justify-content-between mr-1"
                          >
                            <font-awesome-icon
                              icon="columns"
                              class="lg"
                            />
                            <h6 class="icon-header">
                              {{ getEventType(item.interview_type) }}
                            </h6>
                          </div>

                          <div class="d-flex justify-content-between mr-1">
                            <font-awesome-icon icon="map-marker-alt" />
                            <h6 class="icon-header">
                              {{
                                cleanLocation(
                                  item.work_location,
                                  item.location_zone
                                )
                              }}
                            </h6>
                          </div>

                          <div
                            v-if="false"
                            class="d-flex"
                          >
                            <font-awesome-icon icon="dollar-sign" />

                            <h6
                              v-if="item.min_salary == item.max_salary"
                              class="icon-header"
                            >
                              {{
                                item.max_salary === 1 || item.max_salary === "1" ? "Undisclosed" : item.max_salary
                              }}
                              {{
                                item.max_salary === 1 || item.max_salary === "1"
                                  ? ""
                                  : cleanCycles(item.salary_cycle)
                              }}
                            </h6>

                            <h6
                              v-else-if="
                                item.max_salary === 'To be discussed later'
                              "
                              class="icon-header"
                            >
                              {{ item.max_salary }}
                            </h6>

                            <h6
                              v-else
                              class="icon-header"
                            >
                              {{ item.min_salary }} - {{ item.max_salary }}
                              {{ cleanCycles(item.salary_cycle) }}
                            </h6>
                          </div>
                        </div>

                        <div
                          v-if="item.industry && item.industry.length > 0"
                          class="d-flex"
                        >
                          <div class="d-flex mx-1">
                            <div
                              v-for="(tag, index) in item.industry"
                              :key="index"
                            >
                              <b-badge
                                pill
                                variant="dark"
                              >
                                {{ tag }}
                              </b-badge>
                            </div>
                          </div>
                        </div>
                        <!-- v-if="item.organiser_name" -->
                        <h6
                          v-if="false"
                          class="mt-1 ml-1 fw-b"
                          style="font-size: 14px"
                        >
                          <span
                            v-if="item.organiser_name && !grouped()"
                          >An event organised by {{ item.organiser_name }}</span>
                        </h6>

                        <!-- v-else -->
                        <h6
                          v-if="false"
                          class="my-1 text-danger ml-1"
                          style="font-size: 14px"
                        >
                          <span>When you click `Apply Now` button you will be
                            redirected to NTUC's Job Security Council - Jobs
                            Alert telegram bot where you will complete the
                            application.</span>
                        </h6>

                        <div
                          v-if="['mcff', 'effc'].includes(item.source_name)"
                          class="d-flex"
                        >
                          <h6
                            class="my-1 text-warning ml-1 font-weight-bold"
                            style="font-weight: 600 !important"
                          >
                            Source: {{ item.source_name === 'mcf' ? 'MyCareersFuture': 'eFinancialCareers' }}
                          </h6>
                          <font-awesome-icon
                            v-b-tooltip.hover.top="mcf_message"
                            icon="question-circle"
                            class="mt-1 ml-1 text-warning"
                          />
                        </div>

                        <p
                          v-if="!item.external"
                          class="fw-bold text-right"
                          style="font-weight: 400; font-size: 14px"
                        >
                          12 Applications
                        </p>
                        <p
                          v-if="item.external"
                          class="fw-bold text-right"
                          style="font-weight: 400; font-size: 14px"
                        >
                          {{ item.applications }} Applications
                        </p>

                      </div>

                    </b-col>

                    <b-col
                      lg="3"
                      sm="12"
                      class="w-100"
                    >
                      <div class="d-flex flex-row w-100">
                        <div class="divider d-block d-md-none" />

                        <div class="d-flex flex-column ml-1 w-100">
                          <div
                            v-if="item.event_category !== 'group'"
                            class="d-flex flex-column w-100"
                          >
                            <b-button
                              class="mx-auto mt-2 text-center"
                              :to="'/events/' + item.id"
                              variant="primary"
                              block
                            >
                              <feather-icon
                                icon="BookOpenIcon"
                                class="mr-1"
                              />
                              <span
                                class="align-middle"
                              >View More</span></b-button>
                          </div>

                          <div class="d-flex flex-column">

                            <div
                              v-if="item.external"
                              class="d-flex flex-column w-100"
                            >
                              <b-button
                                ref="join"
                                class="mx-auto mt-2 w-100"
                                variant="primary"
                                block
                                @click="fireRedirect(item, $event)"
                              >
                                <feather-icon
                                  icon="CheckIcon"
                                  class=""
                                />
                                <span class="align-middle">Apply Now</span>
                              </b-button>
                            </div>
                            <div v-else>
                              <b-button
                                v-if="
                                  showEventButtons
                                    && checkIDRegistered( registered, item.id) && !checkProfileCompletion()
                                "
                                ref="join"
                                class="mx-auto mt-2 w-100"
                                variant="success"
                                block
                                @click="joinEvent(item, $event)"
                              >
                                <feather-icon
                                  icon="CheckIcon"
                                  class="mr-1"
                                />
                                <span
                                  class="align-middle"
                                >Applied</span></b-button>
                              <div
                                v-else-if="
                                  checkIDRegistered(
                                    registered,
                                    item.id
                                  ) && checkProfileCompletion()
                                "
                                class="d-flex flex-column"
                              >
                                <b-button
                                  variant="warning"
                                  class="mx-auto mt-2 w-100"
                                  block
                                  @click="partialReg"
                                >
                                  <feather-icon
                                    icon="CheckIcon"
                                    class="mr-1"
                                  />
                                  <span class="align-middle">
                                    Incomplete Application
                                  </span>
                                </b-button>

                                <b-alert
                                  v-if="
                                    checkIDRegistered(
                                      registered,
                                      item.id
                                    ) && checkProfileCompletion()
                                  "
                                  class="w-100 h-auto"
                                  variant="warning"
                                  show
                                >
                                  <div class="alert-body">
                                    <feather-icon
                                      class="mr-2"
                                      icon="infoIcon"
                                    />
                                    <span>
                                      Complete it via
                                      {{
                                        $router.currentRoute.path == "/events-list"
                                          ? "the Profile page"
                                          : `Manage My Applications`
                                      }}
                                    </span>
                                  </div>
                                </b-alert>
                              </div>

                              <b-button
                                v-else
                                ref="join"
                                class="mx-auto mt-2"
                                variant="primary"
                                block
                                @click="joinEvent(item, $event)"
                              >
                                <feather-icon
                                  icon="CheckIcon"
                                  class="mr-1"
                                />
                                <span
                                  class="align-middle"
                                >Apply Now</span></b-button>
                            </div>

                            <b-button
                              class="mx-auto mt-2 w-100"
                              variant="primary"
                              block
                              @click="shareEvent(item)"
                            >
                              <feather-icon
                                icon="ShareIcon"
                                class="mr-1"
                              />
                              <span
                                class="align-middle"
                              >Share Job</span></b-button>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <hr v-if="item.note">
                  <b-row v-if="item.note">
                    <p
                      style="font-weight: 600; font-size: 14px;white-space: pre-line;"
                      class="mx-2 mt-1"
                    >
                      <span>
                        Note:
                      </span>
                      <br>
                      <span>
                        {{ item.note }}
                      </span>
                    </p>
                  </b-row>
                </b-card>
              </div>

              <div
                v-if="events_data.length > 0"
                class="d-flex w-100 mb-16"
              >
                <b-button
                  v-if="loadedAllJobs"
                  class="mx-auto"
                  variant="primary"
                  @click="loadMoreJobs"
                >View More Jobs</b-button>
                <p
                  v-else
                  class="vx-col mx-auto"
                >
                  No more available jobs
                </p>
                <!-- <vs-pagination
                class="vx-col sm:ml-12 lg:ml-0"
                :total="total_events.length"
                v-model="current_job_page"
                :max="10"
              ></vs-pagination> -->
              </div>
            </b-col>
            <b-col
              v-else
              class="w-100"
            >
              <h4
                class="grey mx-auto text-center mt-5"
                :class="accountancy_event ? 'text-white' : 'text-dark'"
              >
                {{ event_exist ? 'No available jobs under this section currently.': 'This event has been closed or does not exists.' }}
              </h4>
            </b-col>
          </b-overlay>
        </div>
        <!-- </div> -->
      </div>

      <!-- RegistrationForm Modal -->
      <div class="">
        <b-modal
          ref="popupReg"
          v-model="popupReg"
          centered
          hide-footer
          size="lg"
        >
          <div class="w-100">
            <h6 class="mb-6">
              You will require a HeadHunters HQ account to proceed with the
              application.
            </h6>
            <b-tabs justified>
              <b-tab title="Login">
                <login-page
                  class="w-100"
                  which-page="listings"
                  :event-id="this.$router.currentRoute.path"
                  @user-logged-in="afterUserLoggedIn"
                />
              </b-tab>
              <b-tab title="Registration">
                <RegistrationForm
                  class="w-100"
                  :extra-data="popup_questions"
                  :event-id="event_id"
                  which-page="listings"
                  account-type="Applicant"
                  @user-registered="afterUserRegistered"
                />
              </b-tab>
            </b-tabs>
          </div>
        </b-modal>
      </div>

      <!-- Quiz Modal -->
      <JobPopup
        :activate-popup="popup_quiz"
        :job-questions="popup_questions"
        :save-nric="save_nric"
        :all-applications="registered"
        @application-form="checkForm"
        @application-id="setApplicationId"
      />
      <ProfileCompletionForm
        :activate-popup="popupProfileQuestions"
        @open-job-form="getQuestions"
        @open-completion-form="activateTypeForm"
      />
    </div>
    <div
      v-if="false"
      class="container-fluid"
    >
      <AppFooter class="w-100" />
    </div>
    <b-overlay
      :show="highlightsLoading"
      rounded="sm"
      class="w-100"
      cols="12"
      lg="8"
      sm="12"
    >
      <div
        class="my-3 d-flex flex-column flex-md-row justify-content-center"
        style="gap: 3rem;"
      >

        <div
          v-for="event in highlights"
          :key="event.key"
          class="event-item flex-fill mx-auto mx-md-0 text-din-bold"
        >
          <div class="px-1 px-md-0">
            <div
              class=""
            >
              <a
                :href="event.redirect_url"
                target="_blank"
              >
                <img
                  v-if="['listings-page', 'listings-event'].includes($route.name)"
                  :src="event.event_image"
                  alt="content-img"
                  class="my-1"
                  :class="event.class_names"
                  width="100%"
                >
              </a>
            </div>

            <div
              class="mt-1 text-primary text-medium"
            >
              {{ event.event_type }}
            </div>

            <a
              :href="event.redirect_url"
              target="_blank"
            >
              <div
                style="white-space: pre-line"
                class="mt-1 text-pantone-445 text-medium"
              >{{ event.event_name }}</div>
            </a>

            <div
              class="mt-1 d-flex align-items-center text-regular text-pantone-445"
            >
              <feather-icon
                icon="CalendarIcon"
                class="mr-1"
              />
              {{ event.calendar }}
            </div>
          </div>
        </div>

      </div>
    </b-overlay>

    <div
      class="banner-container text-din-regular text-regular d-flex flex-wrap align-items-center jusitfy-content-center w-100"
      :class="{ 'bg-primary-gradient text-white' : accountancy_event }"
      :style="`padding-bottom: 0rem !important; margin-block: 0rem !important;`"
    >
      <b-row class="m-0 p-0 mb-3 w-100">
        <b-col
          cols="12"
          md="8"
        >
          <b-row
            v-if="false"
            class="d-flex align-items-end justify-content-between"
          >

            <b-col cols="6">
              <div class="">
                <img
                  :src="footerLogoImage"
                  style="height: 4rem"
                  class="w-auto object-scale-down"
                >
              </div>
            </b-col>
          </b-row>

          <b-row>
            <div
              class="p-0 m-0 d-flex align-items-center justify-content-start"
              style="gap: 2rem;"
            >
              <div>
                <div class="">
                  <a
                    class="mr-1"
                    target="_blank"
                    href="https://designsingapore.org/"
                  >
                    <img
                      :src="footerLogoImage"
                      style="height: 4rem;"
                      class="w-auto object-scale-down"
                    >
                  </a>
                  <a
                    class="ml-1"
                    target="_blank"
                    href="https://designsingapore.org/"
                  >
                    <img
                      :src="unescoLogoImage"
                      style="height: 5rem;"
                      class="w-auto object-scale-down"
                    >
                  </a>
                </div>
              </div>
            </div>
          </b-row>
          <b-row>
            <p class="mt-2">
              An initiative of DesignSingapore Council © 2023
            </p>
          </b-row>

          <b-row>
            <b-col
              class="m-0 mt-1 p-0 text-justify"
              cols="12"
            >
              As Singapore’s national agency for design, we champion the use of design to grow business, spur innovation and improve lives.
            </b-col>

            <b-col
              class="mt-2 p-0 d-flex flex-wrap flex-gap-sm"
              cols="12"
            >
              <span class="">
                <a
                  class="text-white"
                  href="https://designsingapore.org/privacy-statement/ "
                  target="_blank"
                >
                  <b-button
                    size="sm"
                    variant="outline-light"
                  >
                    Privacy Statement
                  </b-button>
                </a>
              </span>
              <span class="">
                <a
                  class="text-white"
                  href="https://designsingapore.org/terms-of-use/"
                  target="_blank"
                >
                  <b-button
                    size="sm"
                    variant="outline-light"
                  >
                    Terms of Use
                  </b-button>
                </a>
              </span>
              <span class="">
                <a
                  class="text-white"
                  href="mailto:talent@designsingapore.org"
                  target="_blank"
                >
                  <b-button
                    size="sm"
                    variant="outline-light"
                  >
                    Contact Us
                  </b-button>
                </a>
              </span>
            </b-col>

            <b-col
              class="d-flex d-md-none m-0 mt-3 p-0"
              cols="12"
            >
              <footer-social-media
                :social-medias="socialMedias"
              />
            </b-col>

            <b-col
              class="m-0 mt-3 p-0"
              cols="12"
            >
              Powered by
              <a
                class="text-white"
                href="https://headhuntershq.com"
                target="_blank"
              >
                HeadHunters HQ
              </a>
              <!-- <AppFooter
                class="text-light w-100"
                style="margin: 0 !important;"
              /> -->
            </b-col>
          </b-row>
        </b-col>

        <b-col
          class="d-none d-md-flex flex-column justify-content-center align-items-center"
        >
          <footer-social-media
            :social-medias="socialMedias"
          />
        </b-col>

      </b-row>

    </div>

    <!-- <help-desks-issue-floating /> -->
  </div>
</template>

<script>
import HelpDesksIssueFloating from '@/components/HelpDesksIssueFloating.vue'
import designCouncilImage from '@/assets/images/pages/listings/DESIGNSINGAPORE COUNCIL PTE. LTD - cropped..png'
// import designCouncilBannerImage from '@/assets/images/pages/listings/photo1684334190.jpeg'
import designCouncilBannerImage from '@/assets/images/pages/listings/listings-event-banner.png'
import unescoImage from '@/assets/images/unesco.png'
import designCouncilLogoWhiteImage from '@/assets/images/logo/designsingapore-logo-white.png'
import designCouncilLogo from '@/assets/images/logo/dsg-transparent-logo.png'

import { isUserLoggedIn } from '@/auth/utils'

import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBTooltip,
  VBModal,
  BBadge,
  BOverlay,
  BAlert,
  BTabs,
  BTab,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-slider-component/theme/default.css'
import {
  intersection, orderBy, toInteger, inRange,
} from 'lodash'
import AppNavbarFull from '@/layouts/components/AppNavbarFull.vue'
import AppFooter from '@/layouts/components/AppFooter.vue'
import {
  allIndustries,
  specialIndustries,
  jobFunctions,
} from '@/constants/datastore'
import JobPopup from '@/components/JobPopup.vue'
import LoginPage from '@/views/authentication/login/LoginForm.vue'
import RegistrationForm from '@/views/authentication/registration/RegistrationForm.vue'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import { listingsMixin } from './mixins/listingsMixins'
import FooterSocialMedia from './listings/components/FooterSocialMedia.vue'

export default {
  components: {
    HelpDesksIssueFloating,
    FooterSocialMedia,

    // ListEvent,
    JobPopup,
    AppFooter,
    VueSlider,
    vSelect,
    BCard,
    BBadge,
    BAlert,
    BFormGroup,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BOverlay,

    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    AppNavbarFull,

    LoginPage,
    RegistrationForm,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [listingsMixin, onResizeMixins],
  props: {
    groupEventId: { type: String, default: '' },
  },

  data() {
    return {
      isLoggedIn: false,
      isUserLoggedIn,

      events: [],
      highlights: [],
      latestCompanyImage: designCouncilImage,
      logoImage: designCouncilLogo,
      footerLogoImage: designCouncilLogoWhiteImage,
      unescoLogoImage: unescoImage,
      socialMedias: [
        {
          icon: 'FacebookIcon',
          url: 'https://www.facebook.com/designsingaporecouncil',
        },
        {
          icon: 'LinkedinIcon',
          url: 'https://www.linkedin.com/authwall?trk=qf&originalReferer=https://www.designsingapore.org/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fdesignsingaporecouncil',
        },
        {
          icon: 'InstagramIcon',
          url: 'https://www.instagram.com/designsingapore/',
        },
        {
          icon: 'YoutubeIcon',
          url: 'https://www.youtube.com/channel/UCk6BrOjGPFaubX-JtFaE1mg',
        },
      ],

      preferred_pos: [],
      isLoading: false,
      highlightsLoading: false,
      all_share_icons: [
        {
          name: ['fab', 'facebook-square'],
          styling: 'facebook-color',
          network: 'facebook',
        },
        {
          name: ['fab', 'twitter-square'],
          styling: 'twitter-color',
          network: 'twitter',
        },
        {
          name: ['fab', 'whatsapp-square'],
          styling: 'whatsapp-color',
          network: 'whatsapp',
        },
        {
          name: ['fab', 'linkedin'],
          styling: 'linkedin-color',
          network: 'linkedin',
        },
        {
          name: ['fab', 'facebook-messenger'],
          styling: 'messenger-color',
          network: 'messenger',
        },
        {
          name: ['fab', 'telegram'],
          styling: 'telegram-color',
          network: 'telegram',
        },
      ],
      book: {
        text: 'Register',
        color: 'primary',
      },
      select_event_type: '',
      event_types: [
        { text: 'Single events', value: 'single' },
        { text: 'Group events', value: 'group' },
      ],
      group_preview_popup: false,
      group_preview_link: '',
      selected_job_type: [],
      selected_job_level: [],
      sort_choice: 'Default',
      sort_options: [
        { text: 'Default', value: 'Default' },
        { text: 'Ascending', value: 'Ascending' },
        { text: 'Descending', value: 'Descending' },
      ],
      selected_industry: [],
      industries: [],

      selected_employment_type: [],
      jobLevelOptions: [
        { test: 'Senior Management', value: 'Senior Management' },
        { test: 'Middle Management', value: 'Middle Management' },
        { test: 'Manager', value: 'Manager' },
        { test: 'Professional', value: 'Professional' },
        { test: 'Senior Executive', value: 'Senior Executive' },
        { test: 'Executive', value: 'Executive' },
        { test: 'Junior Executive', value: 'Junior Executive' },
        { test: 'Non-executive', value: 'Non-executive' },
        { test: 'Fresh/Entry level', value: 'Fresh/Entry level' },
      ],
      job_types: [
        { text: 'Part-time', value: 'parttime' },
        { text: 'Full-time', value: 'fulltime' },
      ],
      employment_types: [
        { text: 'Internship/Traineeship', value: 'internship_traineeship' },
        { text: 'Freelance', value: 'freelance' },
        { text: 'Permanent', value: 'permanent' },
        { text: 'Contract', value: 'contract' },
        { text: 'Flexi-work', value: 'flexiwork' },
        { text: 'Temporary', value: 'temporary' },
      ],
      salary_formatter: v => `S$${`${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      selected_location: '',
      selected_event_title: '',
      redirection_link: null,
      all_locations: [
        {
          text: 'North',
          value: 'North',
        },
        {
          text: 'South',
          value: 'South',
        },
        {
          text: 'Central',
          value: 'Central',
        },
        {
          text: 'East',
          value: 'East',
        },
        {
          text: 'West',
          value: 'West',
        },
      ],
      all_dates: [],
      specific_company: '',
      specific_company_image: '',
      event_id: '',
      fullname: '',
      email_address: '',
      show_inside: false,
      popupActive: false,
      image_url: 'default',
      event_exist: false,
      action: 'insert',
      current_item: {},
      popupReg: false,

      // Employments
      popupEmployments: false,
      // Educations
      popupEducations: false,

      item_id: '',
      job: {},
      companies: [],
      registered: [],
      events_data: [],
      all_events_data: [],
      select_tags: [],
      select_date: [],
      select_company: [],
      select_organiser: [],
      organiser_companies: [],
      current_user: '',
      position: '',
      description: '',
      salary_range: [0, 500000],
      salary_range_monthly: [0, Math.round(500000 / 12)],
      salary_range_yearly: [0, 500000],
      min_salary_range: 0,
      max_salary_range: 500000,
      categories: [
        { text: 'Administration, business and management', value: 1 },
        { text: 'Alternative therapies', value: 2 },
        { text: 'Animals, land and environment', value: 3 },
        { text: 'Computing and ICT', value: 4 },
        { text: 'Construction and building', value: 5 },
        { text: 'Design, arts and crafts', value: 6 },
        { text: 'Education and training', value: 7 },
        { text: 'Engineering', value: 8 },
        { text: 'Facilities and property services', value: 9 },
        { text: 'Financial services', value: 10 },
        { text: 'Garage services', value: 11 },
        { text: 'Hairdressing and beauty', value: 12 },
        { text: 'Healthcare', value: 13 },
        { text: 'Heritage, culture and libraries', value: 14 },
        { text: 'Hospitality, catering and tourism', value: 15 },
        { text: 'Legal and court services', value: 16 },
        { text: 'Manufacturing and production', value: 17 },
        { text: 'Performing arts and media', value: 18 },
        { text: 'Retail and customer services', value: 19 },
        { text: 'Science, mathematics and statistics', value: 20 },
        { text: 'Security, uniformed and protective services', value: 21 },
        { text: 'Social sciences and religion', value: 22 },
        { text: 'Sport and leisure', value: 23 },
        { text: 'Transport, distribution and logistics', value: 24 },
      ],
      dates: [],
      popup_event: {},
      current_event_id: '',
      share_popup: false,
      filter_popup: false,
      group_event_image: false,
      share_link: '',
      searchTimer: null,
      selected_status: true,
      all_event_status: [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
      ],
      all_job_functions: [],
      selected_job_function: [],
      total_events: [],
      start_page_count: 0,
      end_page_count: 0,
      current_job_page: 1,
      page_size: 4,
      accountancy_event: true,
      pause_trigger: false,

      load_more_counter: 4,
      expand_icon: 'icon-minimize-2',
      expand_search: true,
      companyImage: '',
      showLayout: true,

      base_listings: 'http://dashboard.headhuntershq.com/listings/',
      // base_listings: 'http://localhost:8082/listings/',
      searchLoading: false,
      jobsLoading: false,
      mcf_message:
        'All information on jobs is published in good faith. e2i does not make any warranties about the completeness, reliability and accuracy of this information. From the jobs portal, you can visit other websites by following hyperlinks on the job ads to external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove any dead/unrelated/inappropriate content or links.',
      listingsPagination: {
        currentPage: 0,
        skipPages: 0,
        limit: 5,
      },
      activeFilters: {
        search: false,
        sort: false,
        company: false,
        location: false,
        employment_type: false,
        selected_industry: false,
        selected_job_function: false,
        select_date: false,
      },

      showEventButtons: true, // use to rerender buttons after submitting so it can turn to applied button

      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 5,
      },

      companyLogosFile: {
        'WOHA ARCHITECTS PTE. LTD.': {
          filename: '1-woha.jpeg',
          external_url: 'https://www.mycareersfuture.gov.sg/job/architecture/interior-designer-woha-architects-8d574458b7f68a65b5f05345b4703502?source=MCF&event=Search',
        },
        'ALEPH PTE. LTD.': {
          filename: '2-aleph.png',
          external_url: 'https://www.mycareersfuture.gov.sg/job/design/design-project-manager-aleph-47de2038202becdaa8cabc05e7f3a6d3?source=MCF&event=Search',
        },
        'OXYGEN STUDIO DESIGNS PTE. LTD.': {
          filename: '8-osd.png',
          external_url: 'https://www.mycareersfuture.gov.sg/job/design/graphics-designer-oxygen-studio-designs-7afd4f8ecae1a16c06b994cc86fbb13d?source=MCF&event=Search',
        },
        'FT CONSULTING PTE. LTD.': {
          filename: 'ftlogo.jpg',
          external_url: '',
        },
        'The Great Eastern Life Assurance Co Ltd': {
          filename: '3-great.jpg',
          external_url: 'https://www.mycareersfuture.gov.sg/job/design/lead-user-experience-design-great-eastern-life-assurance-company-74e7f4263738e9ecbdf0d6df37f15461?source=MCF&event=Search',
        },
        'Rogers and Sons': {
          filename: 'rogers-and-sons.png',
          external_url: '',
        },
        'OuterEdit Private Limited': {
          filename: 'outer-edit.png',
          external_url: '',
        },
        'Julius Baer': {
          filename: 'julius-baer.png',
          external_url: '',
        },
        'DESIGNSINGAPORE COUNCIL PTE. LTD.': {
          filename: 'dsg-transparent-long-logo.png',
          external_url: '',
        },
        // {
        //   company_name: '',
        //   filename: '4-housing.png',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/information-technology/principal-user-experience-designer-housing-development-board-397d7e3efd7d6f21d68b971817e8e1ad?source=MCF&event=Search'
        // },
        // {
        //   company_name: '',
        //   filename: '5-kings.jpg',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/architecture/interior-designer-kingsmen-projects-e2e0607eb76996292b9f2d1627812037?source=MCF&event=Search'
        // },
        // {
        //   company_name: '',
        //   filename: '6-ocbc.jpg',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/banking-finance/experience-designer-oversea-chinese-banking-corporation-6fbeb463e38d29e1edae62b7dcaff784?source=MCF&event=Search'
        // },
        // {
        //   company_name: '',
        //   filename: '7-ong.png',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/architecture/senior-designer-ong-ong-94afe2b04786d21659de5c1322eb0117?source=MCF&event=Search'
        // },
        // {
        //   company_name: '',
        //   filename: '9-osim.png',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/architecture/3d-designersenior-3d-designer-osim-international-7dcd70fbd149243b009b5bc7886da7cd?source=MCF&event=Search'
        // },
        // {
        //   company_name: '',
        //   filename: '10-second.webp',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/design/designer-second-nature-df21a2098293504aa887a717d99f5b8b?source=MCF&event=Search'
        // },
        // {
        //   company_name: 'charles',
        //   filename: '2-charles.svg',
        //   url: '' ,
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/advertising/digital-designer-charles-keith-8895e023048474d19228abc0df07da63?source=MCF&event=Search'
        // },
      },

      companyLogos: [
        // {
        //   company_name: 'WOHA ARCHITECTS PTE. LTD.',
        //   filename: '1-woha.jpeg',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/architecture/interior-designer-woha-architects-8d574458b7f68a65b5f05345b4703502?source=MCF&event=Search',
        // },
        // {
        //   company_name: 'ALEPH PTE. LTD.',
        //   filename: '2-aleph.png',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/design/design-project-manager-aleph-47de2038202becdaa8cabc05e7f3a6d3?source=MCF&event=Search',
        // },
        // {
        //   company_name: 'FT CONSULTING PTE. LTD.',
        //   filename: 'ftlogo.jpg',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/consulting/freelance-creative-designer-ft-consulting-f2fe3df2ccd969410d05f2c78963215c?source=MCF&event=Search',
        // },
        // {
        //   company_name: '',
        //   filename: '3-great.jpg',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/design/lead-user-experience-design-great-eastern-life-assurance-company-74e7f4263738e9ecbdf0d6df37f15461?source=MCF&event=Search'
        // },
        // {
        //   company_name: '',
        //   filename: '4-housing.png',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/information-technology/principal-user-experience-designer-housing-development-board-397d7e3efd7d6f21d68b971817e8e1ad?source=MCF&event=Search'
        // },
        // {
        //   company_name: '',
        //   filename: '5-kings.jpg',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/architecture/interior-designer-kingsmen-projects-e2e0607eb76996292b9f2d1627812037?source=MCF&event=Search'
        // },
        {
          company_name: 'OXYGEN STUDIO DESIGNS PTE. LTD.',
          filename: '8-osd.png',
          url: '',
          external_url: 'https://www.mycareersfuture.gov.sg/job/design/graphics-designer-oxygen-studio-designs-7afd4f8ecae1a16c06b994cc86fbb13d?source=MCF&event=Search',
        },
        // {
        //   company_name: '',
        //   filename: '6-ocbc.jpg',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/banking-finance/experience-designer-oversea-chinese-banking-corporation-6fbeb463e38d29e1edae62b7dcaff784?source=MCF&event=Search'
        // },
        // {
        //   company_name: '',
        //   filename: '7-ong.png',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/architecture/senior-designer-ong-ong-94afe2b04786d21659de5c1322eb0117?source=MCF&event=Search'
        // },
        // {
        //   company_name: '',
        //   filename: '9-osim.png',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/architecture/3d-designersenior-3d-designer-osim-international-7dcd70fbd149243b009b5bc7886da7cd?source=MCF&event=Search'
        // },
        // {
        //   company_name: '',
        //   filename: '10-second.webp',
        //   url: '',
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/design/designer-second-nature-df21a2098293504aa887a717d99f5b8b?source=MCF&event=Search'
        // },
        // {
        //   company_name: 'charles',
        //   filename: '2-charles.svg',
        //   url: '' ,
        //   external_url: 'https://www.mycareersfuture.gov.sg/job/advertising/digital-designer-charles-keith-8895e023048474d19228abc0df07da63?source=MCF&event=Search'
        // },
      ],
      companyLogosCurrentPage: 1,
      companyLogosStart: 1,
      companyLogosEnd: 4,
      companyLogosQuantity: 4, // change this and companyLogoRange to change the number of logos to display
      companyLogosRange: [1, 2, 3, 4], // to make it re-render when
    }
  },
  computed: {

    eventsGroups() {
      return Array.from(Array(Math.ceil(this.events_data.length / 4)).keys())
    },
    loadedAllJobs() {
      if (this.load_more_counter < this.all_events_data.length) {
        return true
      }

      return true
    },
  },
  watch: {
    current_job_page: {
      handler(val, oldVal) {
        this.events_data = this.total_events[val - 1]
      },
    },

    selected_event_title: {
      handler(val, oldVal) {},
    },
    salary_range_monthly: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          if (val[1] !== 0) {
            this.salary_range_yearly = [0, 0]
          }
          this.salary_range = [val[0] * 12, val[1] * 12]
        }
      },
    },
    salary_range_yearly: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          if (val[1] !== 0) {
            this.salary_range_monthly = [0, 0]
          }
          this.salary_range = [val[0], val[1]]
        }
      },
    },
    salary_range: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.searchText()
        }
      },
    },
    selected_employment_type: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
      },
    },
    selected_job_function: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
      },
    },
    select_tags: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
      },
    },
    select_date: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
      },
    },
    select_company: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
      },
    },

    select_organiser: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
      },
    },
    selected_location: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
      },
    },
    selected_job_type: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
      },
    },

    selected_industry: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
      },
    },

    select_event_type: {
      handler(val, oldVal) {
        this.serverParams.page = 1
        this.specificGetEvents()
      },
    },

    sort_choice: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
      },
    },
    popup_quiz: {
      handler(val, oldVal) {
      },
    },
    popupProfileQuestions: {
      handler(val, oldVal) {
      },
    },
    deep: true,
  },

  mounted() {
    // this.autoSlideHiringCompanies('hiring_companies-slider', 1)

    try {
      const next = this.$children[0].$refs.btnclose
      next.$el.addEventListener('click', this.onClickCloseButton, false)
    } catch (error) {}
    this.events_data = [
      {
        job_id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
        position: 'GIFFEST: IMPERFECT',
        min_salary: 3500,
        max_salary: 4500,
        salary_cycle: 'Monthly',
        industry: [],
        featured: false,
        interview_type: 'virtual',
        job_functions: null,
        employment_type: [
          'internship_traineeship',
          'permanent',
        ],
        industry_1: [],
        working_location_address: '',
        working_location_state: 'Singapore',
        working_location_country: 'Singapore',
        working_location_postal_code: null,
        source_name: null,
        application_url: null,
        event_id: 'cc04238b-e18f-4d74-adaf-598d1a99b6ba',
        event_name: 'Design Job',
        start_date: '18th May 2023',
        end_date: '26th Aug 2023',
        start_time: '09:00:00',
        end_time: '21:00:00',
        event_banner: 'event-images/bbe073da-782b-4105-90e1-4dd1b638d734_1684378578.jpeg',
        job_questions: [
          {
            id: '0c587090-351e-42f0-9796-ba9330303c73',
            job_id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
            question: 'Event screening question?',
          },
          {
            id: '4c687829-5ccb-4875-8df3-937d4eebf643',
            job_id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
            question: 'Have you ever create a promotional video?',
          },
        ],
        event_questions: [
          {
            event_id: 'cc04238b-e18f-4d74-adaf-598d1a99b6ba',
            id: '0f85e306-f65d-4a6a-8e11-3fcb2dd1d38a',
            question: 'Event screening question?',
          },
        ],
        company_name: 'EYEYAH!',
        company_image: 'company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg',
        organiser_name: 'Employment and Employability Inc',
        organiser_image: 'company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg',
        job_applications: null,
        last_postal_code: null,
        location_zone: null,
        company: 'Express Delivery Inc',
        external: false,
        note: null,
        event_category: 'join',
        work_location: {
          address: 'National Design Centre Level 1 Atrium, 111 Middle Road',
          country: 'Singapore',
          postal_code: null,
        },
        cleaned_start_date: '18th May',
        cleaned_end_date: '26th Aug 2023',
        cleaned_start_time: '09:00 AM',
        cleaned_end_time: '09:00 PM',
        id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
      },
      {
        job_id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
        position: 'TRASHLAB',
        min_salary: 3500,
        max_salary: 4500,
        salary_cycle: 'Monthly',
        industry: [],
        featured: false,
        interview_type: 'virtual',
        job_functions: null,
        employment_type: [
          'internship_traineeship',
          'permanent',
        ],
        industry_1: [],
        working_location_address: '',
        working_location_state: 'Singapore',
        working_location_country: 'Singapore',
        working_location_postal_code: null,
        source_name: null,
        application_url: null,
        event_id: 'cc04238b-e18f-4d74-adaf-598d1a99b6ba',
        event_name: 'Design Job',
        start_date: '1st Jun 2023',
        end_date: '30st Jun 2023',
        start_time: '10:00:00',
        end_time: '18:00:00',
        event_banner: 'event-images/bbe073da-782b-4105-90e1-4dd1b638d734_1684378578.jpeg',
        job_questions: [
          {
            id: '0c587090-351e-42f0-9796-ba9330303c73',
            job_id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
            question: 'Event screening question?',
          },
          {
            id: '4c687829-5ccb-4875-8df3-937d4eebf643',
            job_id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
            question: 'Have you ever create a promotional video?',
          },
        ],
        event_questions: [
          {
            event_id: 'cc04238b-e18f-4d74-adaf-598d1a99b6ba',
            id: '0f85e306-f65d-4a6a-8e11-3fcb2dd1d38a',
            question: 'Event screening question?',
          },
        ],
        company_name: 'The Idea Co & Miniwiz',
        company_image: 'company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg',
        organiser_name: 'Employment and Employability Inc',
        organiser_image: 'company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg',
        job_applications: null,
        last_postal_code: null,
        location_zone: null,
        company: 'Express Delivery Inc',
        external: false,
        note: null,
        event_category: 'join',
        work_location: {
          address: null,
          country: 'Singapore',
          postal_code: null,
        },
        cleaned_start_date: '1st Jun',
        cleaned_end_date: '30th Jun 2023',
        cleaned_start_time: '10:00 AM',
        cleaned_end_time: '06:00 PM',
        id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
      },
      {
        job_id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
        position: 'Fifty Years of Singapore Design Exhibition',
        min_salary: 3500,
        max_salary: 4500,
        salary_cycle: 'Monthly',
        industry: [],
        featured: false,
        interview_type: 'virtual',
        job_functions: null,
        employment_type: [
          'internship_traineeship',
          'permanent',
        ],
        industry_1: [],
        working_location_address: '',
        working_location_state: 'Singapore',
        working_location_country: 'Singapore',
        working_location_postal_code: null,
        source_name: null,
        application_url: null,
        event_id: 'cc04238b-e18f-4d74-adaf-598d1a99b6ba',
        event_name: 'Design Job',
        start_date: '10th Mar 2023',
        end_date: '31st Dec 2023',
        start_time: '09:00:00',
        end_time: '21:00:00',
        event_banner: 'event-images/bbe073da-782b-4105-90e1-4dd1b638d734_1684378578.jpeg',
        job_questions: [
          {
            id: '0c587090-351e-42f0-9796-ba9330303c73',
            job_id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
            question: 'Event screening question?',
          },
          {
            id: '4c687829-5ccb-4875-8df3-937d4eebf643',
            job_id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
            question: 'Have you ever create a promotional video?',
          },
        ],
        event_questions: [
          {
            event_id: 'cc04238b-e18f-4d74-adaf-598d1a99b6ba',
            id: '0f85e306-f65d-4a6a-8e11-3fcb2dd1d38a',
            question: 'Event screening question?',
          },
        ],
        company_name: 'DesignSingapore Council',
        company_image: 'company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg',
        organiser_name: 'Employment and Employability Inc',
        organiser_image: 'company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg',
        job_applications: null,
        last_postal_code: null,
        location_zone: null,
        company: 'Express Delivery Inc',
        external: false,
        note: null,
        event_category: 'join',
        work_location: {
          address: null,
          country: 'Singapore',
          postal_code: null,
        },
        cleaned_start_date: '10th Mar',
        cleaned_end_date: '31st Dec 2023',
        cleaned_start_time: '09:00 AM',
        cleaned_end_time: '09:00 PM',
        id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
      },
      {
        job_id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
        position: 'NDC Guided Tours: The Many Lifetimes of 111 Middle Road',
        min_salary: 3500,
        max_salary: 4500,
        salary_cycle: 'Monthly',
        industry: [],
        featured: false,
        interview_type: 'virtual',
        job_functions: null,
        employment_type: [
          'internship_traineeship',
          'permanent',
        ],
        industry_1: [],
        working_location_address: '',
        working_location_state: 'Singapore',
        working_location_country: 'Singapore',
        working_location_postal_code: null,
        source_name: null,
        application_url: null,
        event_id: 'cc04238b-e18f-4d74-adaf-598d1a99b6ba',
        event_name: 'Design Job',
        start_date: '1st Feb 2023',
        end_date: '31st Dec 2023',
        start_time: '09:00:00',
        end_time: '21:00:00',
        event_banner: 'event-images/bbe073da-782b-4105-90e1-4dd1b638d734_1684378578.jpeg',
        job_questions: [
          {
            id: '0c587090-351e-42f0-9796-ba9330303c73',
            job_id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
            question: 'Event screening question?',
          },
          {
            id: '4c687829-5ccb-4875-8df3-937d4eebf643',
            job_id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
            question: 'Have you ever create a promotional video?',
          },
        ],
        event_questions: [
          {
            event_id: 'cc04238b-e18f-4d74-adaf-598d1a99b6ba',
            id: '0f85e306-f65d-4a6a-8e11-3fcb2dd1d38a',
            question: 'Event screening question?',
          },
        ],
        company_name: 'DesignSingapore Council',
        company_image: 'company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg',
        organiser_name: 'Employment and Employability Inc',
        organiser_image: 'company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg',
        job_applications: null,
        last_postal_code: null,
        location_zone: null,
        company: 'Express Delivery Inc',
        external: false,
        note: null,
        event_category: 'join',
        work_location: {
          address: null,
          country: 'Singapore',
          postal_code: null,
        },
        cleaned_start_date: '1st Feb',
        cleaned_end_date: '31st Dec 2023',
        cleaned_start_time: '09:00 AM',
        cleaned_end_time: '09:00 PM',
        id: '90b6dabd-eace-4b34-b737-7716b2e94dbf',
      },
    ]
    this.events = [

      {
        event_name: 'Virtual Career Fair: Information, Communications & Media Roles',
        event_image: require('@/assets/images/placeholder/DSG/vcf_icm.jpg'),
        calendar: '4 – 25 September 2023​',
        event_type: 'Event | Workshops',
        redirect_url: 'https://www.careergrit.sg/virtual-career-fair-details/MBA4JRHE3FLSY4L7PIFNAZUGJ5Z7D5/information--communications--amp--media-roles',
        class_names: 'design-crawl',
      },
      {
        event_name: 'Singapore Design Week:\nDesign Crawl – SG Mark Winners Sharing',
        // event_image: require('@/assets/images/placeholder/DSG/dsg-event-transparent.png'),
        event_image: require('@/assets/images/placeholder/DSG/design-crawl.png'),
        calendar: '21 September 2023 6:30 PM - 8:30 PM​',
        event_type: 'Event | Workshops',
        redirect_url: 'https://www.eventbrite.sg/e/singapore-design-week-tickets-687644291587',
        class_names: 'design-crawl',
      },
      {
        event_name: 'IMPACT 2023 x Design Research Forum (Discount Code: IMPACT2023IHL)',
        event_image: require('@/assets/images/placeholder/DSG/impact-1.jpg'),
        calendar: '26 September 2023 9:00 AM - 6:00 PM​',
        event_type: 'Event | Workshops',
        redirect_url: 'https://wis.ntu.edu.sg/pls/webexe88/REGISTER_NTU.REGISTER?EVENT_ID=OA23081510201144',
        class_names: 'design-crawl',
      },
      // {
      //   event_name: 'National Design Project 2023',
      //   event_image: require('@/assets/images/placeholder/DSG/event-2.jpg'),
      //   calendar: 'Registration closes on: 28 June 2023',
      //   event_type: 'Event | Workshops',
      //   redirect_url: 'https://designsingapore.org/events/national-design-project-2023/',
      // },
      // {
      //   event_name: '‘P*DA Tours – Design that Cares’ by Monster Day Tours',
      //   event_image: require('@/assets/images/placeholder/DSG/event-3.jpg'),
      //   calendar: 'Private Tour - Available Upon Request',
      //   event_type: 'Event | Tours',
      //   redirect_url: 'https://designsingapore.org/events/pda-tours-design-that-cares-by-monster-day-tours/',
      // },
    ]

    // this.events = [
    //   {
    //     "event_name": "GIFFEST: IMPERFECT",
    //     "start_date": "18th May 2023",
    //     "end_date": "26th Aug 2023",
    //     "start_time": "09:00:00",
    //     "end_time": "21:00:00",
    //     "event_banner": "event-images/bbe073da-782b-4105-90e1-4dd1b638d734_1684378578.jpeg",
    //     "company_name": "EYEYAH!",
    //     "company_image": "company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg",
    //     "organiser_name": "EYEYAH!",
    //     "organiser_image": "company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg",
    //     "work_location": {
    //       "address": "National Design Centre Level 1 Atrium, 111 Middle Road",
    //       "country": "Singapore",
    //       "postal_code": null
    //     },
    //     "cleaned_start_date": "18th May",
    //     "cleaned_end_date": "26th Aug 2023",
    //     "cleaned_start_time": "09:00 AM",
    //     "cleaned_end_time": "09:00 PM",
    //   },
    //   {
    //     "event_name": "National Design Project 2023",
    //     "start_date": "1st Jun 2023",
    //     "end_date": "30st Jun 2023",
    //     "start_time": "10:00:00",
    //     "end_time": "18:00:00",
    //     "event_banner": "event-images/bbe073da-782b-4105-90e1-4dd1b638d734_1684378578.jpeg",
    //     "company_name": "The Idea Co & Miniwiz",
    //     "company_image": "company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg",
    //     "organiser_name": "The Idea Co & Miniwiz",
    //     "organiser_image": "company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg",
    //     "work_location": {
    //       "address": null,
    //       "country": "Singapore",
    //       "postal_code": null
    //     },
    //     "cleaned_start_date": "1st Jun",
    //     "cleaned_end_date": "30th Jun 2023",
    //     "cleaned_start_time": "10:00 AM",
    //     "cleaned_end_time": "06:00 PM",
    //   },
    //   {
    //     "event_name": "TRASHLAB",
    //     "start_date": "1st Jun 2023",
    //     "end_date": "30st Jun 2023",
    //     "start_time": "10:00:00",
    //     "end_time": "18:00:00",
    //     "event_banner": "event-images/bbe073da-782b-4105-90e1-4dd1b638d734_1684378578.jpeg",
    //     "company_name": "The Idea Co & Miniwiz",
    //     "company_image": "company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg",
    //     "organiser_name": "The Idea Co & Miniwiz",
    //     "organiser_image": "company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg",
    //     "work_location": {
    //       "address": null,
    //       "country": "Singapore",
    //       "postal_code": null
    //     },
    //     "cleaned_start_date": "1st Jun",
    //     "cleaned_end_date": "30th Jun 2023",
    //     "cleaned_start_time": "10:00 AM",
    //     "cleaned_end_time": "06:00 PM",
    //   },
    // ]

    this.event_exist = true
    this.group_event_image = designCouncilBannerImage

    this.getHighlights()
  },
  created() {
    if (isUserLoggedIn()) {
      this.isLoggedIn = true
    }

    if (this.$router.currentRoute.path.includes('/dtm')) {
      this.showLayout = true
    } else {
      this.showLayout = false
    }
    if (window.screen.width < 769) {
      this.expand_search = false
    }
    this.end_page_count = this.page_size
    this.current_event_id = this.$route.params.event_id
    // this.getUser()
    // const { token } = this.$store.state.auth.ActiveUser
    this.email_address = this.$store.state.auth.ActiveUser
      ? this.$store.state.auth.ActiveUser.email_address
      : null
    // this.$http.defaults.headers.common["Authorization"] = `Token ${token}`;
    this.industries = allIndustries

    this.industries = orderBy(this.industries, ['text'], ['asc'])
    this.all_locations = orderBy(this.all_locations, ['text'], ['asc'])
    this.employment_types = orderBy(this.employment_types, ['text'], ['asc'])
    this.job_types = orderBy(this.job_types, ['text'], ['asc'])

    // typeform
    this.prefIndustryOptions = orderBy(allIndustries, ['value'], ['asc'])

    this.searchLoading = false
    this.jobsLoading = false

    // if (this.groupEventId.length > 2) {
    //   this.getEvents(this.groupEventId, 'group_event')
    // }
    // this.specificGetEvents()

    this.getHiringCompanies()
  },
  methods: {
    getHiringCompanies() {
      // TODO: get companies from database

      this.$http
        .get('/api/listings/hiring-companies')
        .then(response => {
          const { success, hiring_companies } = response.data

          if (success) {
            this.companyLogos = []

            hiring_companies.forEach(company => {
              this.companyLogos.push({
                company_name: company.employer_company_name,
                // filename: this.companyLogosFile[company.employer_company_image],
                filename: this.companyLogosFile[company.employer_company_name].filename,
              })
            })

            this.companyLogos.forEach(logo => {
              logo.url = `/listings?filtered_companies=${logo.company_name}`
            })
            this.companyLogosEnd = this.companyLogosEnd > this.companyLogos.length ? this.companyLogos.length : this.companyLogosEnd
            this.companyLogosRange = this.range(this.companyLogosStart, this.companyLogosEnd)
          }
        })
        .catch(error => {})
    },
    range(start, end) {
      return Array(end - start + 1).fill().map((_, idx) => start + idx)
    },
    scrollCompanyLogos(pageNumber) {
      this.companyLogosCurrentPage = pageNumber
      this.companyLogosStart = this.companyLogosQuantity * pageNumber - (this.companyLogosQuantity - 1)
      this.companyLogosEnd = this.companyLogosQuantity * pageNumber
      if (this.companyLogosEnd > this.companyLogos.length) {
        this.companyLogosEnd = this.companyLogos.length
      }
      this.companyLogosRange = this.range(this.companyLogosStart, this.companyLogosEnd)
    },
    updateApplications(val) {
      this.registered = val
      this.$forceUpdate()
    },
    checkForm(val) {
      this.popup_quiz = val
    },
    activateTypeForm(val) {
      this.popupProfileQuestions = val
    },
    getQuestions(val) {
      this.popup_quiz = true
      this.popup_questions = val.questions
      this.save_nric = val.save_nric
    },
    checkProfileCompletion() {
      let incomplete = true
      try {
        incomplete = this.$store.state.auth.ActiveUser.profile_status.incomplete
      } catch (error) {
        incomplete = true
      }
      return incomplete
    },
    loadMoreJobs() {
      this.listingsPagination.currentPage += 5
      this.listingsPagination.skipPages += 5

      this.serverParams.page += 1
      this.specificGetEvents()
    },
    cleanLocation(location, zone) {
      let { address } = location
      const { country } = location
      address = !this.$isEmpty(address) ? address.replace(`${country} `, '') : address

      let currentZone = zone
      if (currentZone === undefined || currentZone === null) {
        currentZone = 'NA'
      }
      return `${currentZone} (${location.country} ${location.postal_code})`.replace(' null', '').replace('null', '')
    },
    partialReg() {
      this.$bvModal
        .msgBoxConfirm(
          "Currently, your application isn't viewable by the employer since you haven't completed your profile."
            + ' \n\nClicking the button below will redirect you to your profile page where you can fill up the required details.',
          {
            title: 'Incomplete Profile',
            size: 'lg',
            okVariant: 'warning',
            okTitle: 'My Profile',
            cancelTitle: 'Go Back',
            cancelVariant: 'primary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            window.location.href = '/profile'
          }
        })
    },

    resetFilters() {
      this.activeFilters = {
        search: false,
        sort: false,
        company: false,
        location: false,
        employment_type: false,
        selected_industry: false,
        selected_job_function: false,
        select_date: false,
      }
      this.selected_event_title = ''
      this.pause_trigger = true
      this.sort_choice = 'Default'
      this.select_company = []
      this.select_organiser = []
      this.selected_location = ''
      this.salary_range = [0, 500000]
      this.selected_job_type = []
      this.selected_employment_type = []
      this.selected_industry = []
      this.selected_job_function = []
      this.select_date = []
      this.specificGetEvents()
      //
    },
    grouped() {
      const { company } = this.$route.params
      return company
    },
    fireRedirect(item) {
      window.open(item.redirection_url, '_blank')
    },

    checkRedirection() {
      if (this.redirection_link) {
        window.open(this.redirection_link, '_blank')
      }
    },
    specificGetEvents() {
      this.jobsLoading = true
      this.searchLoading = true
      this.load_more_counter = 4
      if (this.$route.params.company !== undefined) {
        this.specific_company = this.$route.params.company

        if (this.$route.params.company.endsWith('_g')) {
          const eventId = this.$route.params.company.replace('_g', '')
          if (eventId === '61de74bc21c14eb016e518cb' || eventId === 'e88e4812-82c8-4d49-8aa1-0b3e3129bb43') {
            this.industries = specialIndustries
          } else if (eventId === '61dcd0dc62adac05c99714c4' || eventId === 'ed14fa6a-0dc1-4fa2-a6ca-53d5daad5d2c') {
            this.accountancy_event = true
            this.$emit('accountancy-event', this.accountancy_event)
          }
          this.getEvents(eventId, 'group_event')
        } else {
          const eventId = this.$route.params.company
          if (this.$route.params.company === '61de74bc21c14eb016e518cb' || eventId === 'e88e4812-82c8-4d49-8aa1-0b3e3129bb43') {
            this.industries = specialIndustries
          } else if (eventId === '61dcd0dc62adac05c99714c4' || eventId === 'ed14fa6a-0dc1-4fa2-a6ca-53d5daad5d2c') {
            this.accountancy_event = true
            this.$emit('accountancy-event', this.accountancy_event)
          }
          this.getEvents(this.$route.params.company_id, 'employer')
        }
      } else {
        this.getEvents()
      }
    },
    cleanCycles(dirtyCycle) {
      const equivCycles = {
        yearly: 'per Year',
        monthly: 'per Month',
        weekly: 'per Week',
        daily: 'per Day',
        hourly: 'per Hour',
      }

      const cleanedCycle = !this.$isEmpty(dirtyCycle) ? equivCycles[dirtyCycle.toLowerCase()] : ''

      return cleanedCycle
    },
    searchText() {
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        this.specificGetEvents()
      }, 1000)
    },
    shareEvent(item) {
      this.current_item = item
      this.share_link = this.getLink(item.id)
      this.$refs['share-job-modal'].toggle('#toggle-btn')
      this.share_popup = true
    },
    filterEvent() {
      this.$refs['filter-job-modal'].toggle('#toggle-btn')
    },
    filterPosition() {
      this.$refs['filter-position-modal'].toggle('#toggle-btn')
    },
    filterEmployer() {
      this.$refs['filter-employer-modal'].toggle('#toggle-btn')
    },
    filterWork() {
      this.$refs['filter-work-modal'].toggle('#toggle-btn')
    },
    filterSalary() {
      this.$refs['filter-salary-modal'].toggle('#toggle-btn')
    },
    filterEmployment() {
      this.$refs['filter-employment-modal'].toggle('#toggle-btn')
    },
    filterSort() {
      this.$refs['filter-sort-modal'].toggle('#toggle-btn')
    },
    getEventType(value) {
      if (value === 'virtual') {
        return 'Virtual Interview'
      }
      if (value === 'physical') {
        return 'In-Person Interview'
      }
      if (value === 'speed_interview') {
        return 'Virtual Speed Interview'
      }
      return 'NA'
    },
    goToEvents(eventId) {
      this.event_id = eventId

      localStorage.setItem('current_event', eventId)
      // let route = this.$router.resolve({ path: "/events/" + event_id });
      //   this.group_preview_link = route.href
      this.group_preview_popup = true
      // let route = this.$router.resolve({ path: "/events/" + event_id });

      // window.open(route.href, "_blank");
    },
    getLink(eventId) {
      return `${window.location.origin}/events/${eventId}`
    },
    onCopy(eventId) {
      this.copyTextToClipboard(`${window.location.origin}/events/${eventId}`)
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Job Link Alert',
            icon: 'AlertCircleIcon',
            text: 'Job link copied successfully',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )
    },

    bookEvent() {
      if (this.book.text === 'Applied') {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Job Application Update',
              icon: 'AlertCircleIcon',
              text: 'You have already applied for this job.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else if (this.email_address.length > 4 && this.fullname.length > 3) {
        const all_data = {
          event_id: this.event_id,
          email_address: this.email_address,
          fullname: this.fullname,
          approved: false,
          interviewed: false,
          company: this.$store.state.auth.ActiveUser.company_name,
        }
        this.$http
          .post('/api/book', { data: all_data })
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Registration Update',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              // this.book.text = "Registered";
              // this.book.color = "success";
              this.popupReg = false
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Registration Update',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Invalid Data',
              icon: 'AlertCircleIcon',
              text: 'Please enter a valid email address or full name',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    viewMore(item) {
      localStorage.setItem('current_event', item.id)
      this.show_inside = true
      this.job = item
      this.popupActive = true
    },
    trimMyString(string) {
      const start = 0
      const maxLength = 200
      // Note - `start` is if I want to start after some point of the string
      if (string.length > maxLength) {
        const trimmedString = string.substr(start, maxLength)
        return `${trimmedString.substr(
          start,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')),
        )} ......`
      }
      return string
    },
    filter_events(data) {
      // this.$vs.loading({ container: "#div-with-loading", scale: 0.6 });

      let new_events = false
      const min_salary = toInteger(data.min_salary)
      const max_salary = toInteger(data.salary)
      const salary_cycle = data.salary_cycle.toLowerCase()
      let multiplier = 1

      if (salary_cycle === 'yearly') {
        multiplier = 1
      } else if (salary_cycle === 'weekly') {
        multiplier = 52
      }
      const salary = inRange(
        min_salary * multiplier,
        this.salary_range[0],
        this.salary_range[1],
      )
        || inRange(
          max_salary * multiplier,
          this.salary_range[0],
          this.salary_range[1],
        )

      const comp = this.select_company.length > 0
        ? this.select_company.includes(data.company)
        : true

      const organiser = this.select_organiser.length > 0
        ? this.select_organiser.includes(data.organiser)
        : true
      const location = this.selected_location.length > 0
        ? this.selected_location.includes(data.location_zone)
        : true

      // let status = this.selected_status.length > 0 ? this.selected_status : true;
      const similar_industry = intersection(this.selected_industry, data.tags)

      const tags = this.selected_industry.length > 0 ? similar_industry.length > 0 : true

      const event_type = this.select_event_type.length > 0
        ? this.select_event_type.includes(data.event_category)
        : true

      const similar_job_functions = intersection(
        this.selected_job_function,
        data.job_functions,
      )
      const job_function = this.selected_job_function.length > 0
        ? similar_job_functions.length > 0
        : true

      const employment_type = this.selected_employment_type.length > 0
        ? this.selected_employment_type.some(v => data.employment_type.includes(v))
        : true

      const job_type = this.selected_job_type.length > 0
        ? this.selected_job_type.includes(data.job_type)
        : true

      const date = this.select_date.length > 0
        ? this.select_date.includes(data.start_date)
        : true

      const title = this.selected_event_title.length > 0
        ? data.position
          .toLowerCase()
          .includes(this.selected_event_title.toLowerCase())
        : true

      if (
        comp
        && organiser
        && tags
        && location
        && event_type
        && job_type
        && date
        && title
        && job_function
        && employment_type
        && salary
      ) {
        new_events = true
      }

      return new_events
      // this.events_data = new_events;
      // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
    },

    smartFilter(data) {
      const { available_job_functions } = data
      const { available_job_types } = data
      const { available_industries } = data
      const { available_employment_types } = data

      if (available_job_functions.length > 0) {
        this.all_job_functions = available_job_functions.map(industry => ({ text: industry, value: industry }))
      } else {
        this.all_job_functions = []
      }

      if (available_job_types.length > 0) {
        this.job_types = this.job_types.filter(jobType => (jobType.value.length > 0
          ? available_job_types.includes(jobType.value)
          : true))
      } else {
        // this.job_types = []
      }

      if (available_industries.length > 0) {
        this.industries = available_industries.map(industry => ({ text: industry, value: industry }))
      } else {
        this.industries = []
      }

      if (available_employment_types.length > 0) {
        this.employment_types = this.employment_types.filter(empTypes => available_employment_types.includes(empTypes.value))
      } else {
        this.employment_types = []
      }
    },
    firstSelection(field) {
      if (!this.activeFilters[field]) {
        this.events_data = []
        this.all_events_data = []
        this.registered = {}
        this.listingsPagination.skipPages = 0
      }

      this.activeFilters[field] = true
    },
    getEvents(companyFilter, special = '') {
      const { email_address } = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser
        : { email_address: null }

      this.current_user = email_address

      const companyName = this.$route.params.company_name

      const all_event_group = []

      function attachSymbol(text) {
        return text.length > 0 ? '&' : '?'
      }

      this.serverParams.columnFilters = {}

      if (special === 'group_event') {
        this.serverParams.columnFilters.group_event = companyFilter
      }

      if (this.sort_choice !== 'Default') {
        this.serverParams.columnFilters.sort_position = this.sort_choice
        this.firstSelection('sort')
        // this.serverParams.columnFilters.sort_position = this.sort_choice
      }

      if (companyName) {
        this.serverParams.columnFilters.company_name = companyName
      }

      if (this.selected_event_title.length > 0) {
        this.serverParams.columnFilters.query = this.selected_event_title
        this.firstSelection('search')
      }

      if (this.select_company.length > 0) {
        this.serverParams.columnFilters.filtered_companies = this.select_company
        this.firstSelection('employer')
      }

      if (this.select_organiser.length > 0) {
        this.serverParams.columnFilters.filtered_organisers = this.select_organiser
      }

      if (this.selected_location.length > 0) {
        this.serverParams.columnFilters.filtered_locations = this.selected_location
        this.firstSelection('location')
      }

      if (this.selected_employment_type.length > 0) {
        this.serverParams.columnFilters.filtered_employment_types = this.selected_employment_type
        this.firstSelection('employment_type')
      }

      if (this.selected_industry.length > 0) {
        this.serverParams.columnFilters.filtered_industries = this.selected_industry
        this.firstSelection('selected_industry')
      }

      if (this.selected_job_function.length > 0) {
        this.serverParams.columnFilters.filtered_job_functions = this.selected_job_function
        this.firstSelection('selected_job_function')
      }

      if (this.select_date.length > 0) {
        this.serverParams.columnFilters.filtered_dates = this.select_date
        this.firstSelection('select_date')
      }

      if (this.salary_range.length > 0) {
        this.serverParams.columnFilters.filtered_salary = this.salary_range
      }
      if (this.select_date.length > 0) {
        this.serverParams.columnFilters.filtered_dates = this.select_date
      }

      if (this.selected_job_type.length > 0) {
        this.serverParams.columnFilters.filtered_job_types = this.selected_job_type
        this.firstSelection('selected_industry')
      }

      let query = ''
      if (this.serverParams.page === 1) {
        this.events_data = []
        this.all_events_data = []
        this.registered = {}
      }

      query = `?params=${JSON.stringify(this.serverParams)}`

      this.$http
        .get(`/api/all-events${query}`)
        .then(response => {
          if (response.data.success) {
            this.event_exist = true
            this.registered = {
              ...this.registered,
              ...response.data.registered,
            }
            this.companies = response.data.companies
            this.organiser_companies = response.data.organisers

            this.companies = orderBy(this.companies, ['text'], ['asc'])
            this.organiser_companies = orderBy(
              this.organiser_companies,
              ['text'],
              ['asc'],
            )
            this.min_salary_range = Math.round(response.data.min_max_salary[0] * 12)
            this.max_salary_range = Math.round(response.data.min_max_salary[1] * 12)
            this.all_locations = response.data.locations
            const jobFunctions = response.data.available_job_functions.map(item => ({ text: item, value: item }))

            this.all_job_functions = orderBy(jobFunctions, ['text'], ['asc'])
            this.all_dates = response.data.all_dates
            this.all_dates = orderBy(this.all_dates, ['text'], ['asc'])
            this.smartFilter(response.data)

            // eslint-disable-next-line no-restricted-syntax
            for (const item of response.data.message) {
              const output = item
              this.events_data.push(item)
              if (this.$route.params.company) {
                this.companyImage = item.company_image
                this.$emit('image-update', item.organiser_image)
                // this.group_event_image = item.event_banner
                this.group_event_image = designCouncilBannerImage
              }
            }
          } else {
            this.event_exist = false
            this.searchLoading = false
          }
          this.pause_trigger = false
          this.jobsLoading = false
          this.searchLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error)
          this.pause_trigger = false
          this.jobsLoading = false
          this.searchLoading = false
        })

      // this.$http
      //   .get(`/api/top-events`)
      //   .then(response => {
      //     console.log(response)
      //   })
      //   .catch(error => {
      //     this.$handleErrorResponse(error)
      //   })
      // this.$http
      //   .get(`/api/top-jobs`)
      //   .then(response => {
      //     console.log(response)
      //   })
      //   .catch(error => {
      //     this.$handleErrorResponse(error)
      //   })
    },
    getHighlights() {
      const serverParams = {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 3,
      }
      const query = `?params=${JSON.stringify(serverParams)}`
      this.highlightsLoading = true

      this.$http
        .get(`/api/highlights${query}`)
        .then(response => {
          const { success, output } = response.data

          output.forEach(async event => {
            let calendar = ''
            let start_date = new Date(event.start_date)
            if (new Date(event.start_date).date !== new Date(event.end_date).date) {
              start_date = start_date.toLocaleDateString('en-GB', {
                day: 'numeric',
              })

              let end_date = new Date(event.end_date)
              end_date = end_date.toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })
              calendar += `${start_date} - ${end_date}`
            } else {
              let start_date = new Date(event.start_date)
              start_date = start_date.toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })

              calendar += `${start_date}`
            }

            if (event.start_time !== event.end_time) {
              let start_time = new Date()
              start_time.setHours(event.start_time.split(':')[0])
              start_time.setMinutes(event.start_time.split(':')[1])
              start_time.setSeconds(event.start_time.split(':')[2])
              start_time = start_time.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })

              let end_time = new Date()
              end_time.setHours(event.end_time.split(':')[0])
              end_time.setMinutes(event.end_time.split(':')[1])
              end_time.setSeconds(event.end_time.split(':')[2])
              end_time = end_time.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })

              calendar += ` ${start_time} - ${end_time}`
            }

            const image_url = this.$isNotEmpty(event.banner_image) ? await this.$generatePresignedUrl(event.banner_image) : require('@/assets/images/placeholder/no-image.jpg')

            this.highlights.push({
              event_name: event.name,
              event_image: image_url,
              calendar,
              event_type: 'Event | Workshops',
              redirect_url: event.redirection_link,
              class_names: 'design-crawl',
            })
            this.highlightsLoading = false
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    extract_values() {
      const selected_tags = []
      for (const data of this.categories) {
        for (const categ of this.select_tags) {
          if (data.value === categ) {
            selected_tags.push(data.text)
          }
        }
      }
      let selected_date = ''
      for (const data of this.dates) {
        if (data.value === this.select_date) {
          selected_date = data.text
        }
      }

      const all_data = {
        position: this.position,
        description: this.description,
        date: selected_date,
        tags: selected_tags,
        image_url: this.image_url,
      }
      return all_data
    },

    onClickCloseButton() {
      this.$emit('closePopup')
    },

    cleanerIntervals(start_date, end_date) {
      if (start_date === end_date) {
        return `happening on ${start_date}`
      }
      return `happening from ${start_date} to ${end_date}`
    },

    checkIDRegistered(registered, job_id) {
      const id = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser.id
        : null
      if (registered) {
        if (Object.keys(registered).includes(job_id)) {
          return registered[job_id].includes(id)
        }
        return false
      }
      return false
    },

    // autoSlideHiringCompanies(divId = 'hiring_companies-slider', speed = 1) {
    //   const div = document.getElementById(divId)

    //   // Scroll the div to the left
    //   div.scrollLeft = div.scrollLeft + 10
    // },
    autoSlideHiringCompanies(divId = 'hiring_companies-slider', speed = 0.9) {
      // Get the div element
      const div = document.getElementById(divId)
      const caontinerItem = document.getElementById(`hiring_companies-slider-item_${1}`)

      // Set the scroll speed
      const scrollSpeed = speed || 10
      // Create a function to scroll the div
      const scrollDiv = () => {
        // Get the current scroll position
        const { scrollLeft } = div

        // Scroll the div to the right
        div.scrollLeft = (scrollLeft) + (caontinerItem.offsetWidth * 3)
        // div.scrollLeft = (scrollLeft - div.scrollWidth) + 1

        // If the div is at the end, reset the scroll position to 0
        if (div.scrollLeft >= (div.scrollWidth - (caontinerItem.offsetWidth * 4.05))) {
          div.scrollLeft = 0
        }
      }

      // Set the div to scroll automatically
      const scrollInterval = setInterval(scrollDiv, 2000)
    },

    // autoSlideHiringCompanies() {
    //   const divId = 'hiring_companies-slider'
    //   const speed = .9

    //   // Get the div element
    //   const div = document.getElementById('hiring_companies-slider')

    //   // Set the scroll speed
    //   const scrollSpeed = speed || 10;

    //   // Create a function to scroll the div
    //   const scrollDiv = () => {
    //     // Get the current scroll position
    //     let scrollLeft = div.scrollLeft

    //     // Scroll the div to the right
    //     div.scrollLeft = scrollLeft + scrollSpeed;

    //     // If the div is at the end, stop scrolling
    //     if (scrollLeft >= div.scrollWidth - 10) {
    //       // clearInterval(scrollInterval);

    //       div.scrollLeft = 0

    //     }
    //   };

    //   // Set the div to scroll automatically
    //   const scrollInterval = setInterval(scrollDiv, 2);
    // }
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.design-crawl{
  height: 210px!important;
  max-width: 75ch;
}
.v-select input {
  cursor: pointer;
}
// div {
//   white-space: pre-wrap;
// }
.dsg-h-text {
  height: 4ch;

  // Extra small (xs)
  @media (max-width: 575.98px) {
    height: 4ch;
  }

  // Small (sm)
  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 4ch;
  }

  // Medium (md)
  @media (min-width: 768px) and (max-width: 991.98px) {
    height: 5ch;
  }

  // Large (lg)
  @media (min-width: 992px) and (max-width: 1199.98px) {
    height: 5ch;
  }

  // Extra large (xl)
  @media (min-width: 1200px) {
  }

  // Extra extra large (xxl)
  @media (min-width: 1400px) {
  }
}

.sticky-element {
  position: -webkit-sticky !important; /* Safari */
  position: static !important;
  top: 0;
}
.vue-slider-process {
  background-color: $primary !important;
}

.vue-slider-dot-tooltip-text {
  border-color: $main !important;
  background-color: $primary !important;
}
.vue-slider-dot-tooltip-inner {
  border-color: $primary !important;
  background-color: $primary !important;
}
.image-size {
  width: 100%;

  // @media (max-width: 800px) {
  //   width: 100%;
  //   object-fit: fill!important;
  // }
  // @media (min-width: 1024px) and (max-width: 1440px) {
  //   // width: 1000px;
  //   // background-color: darkblue;
  // }

  // @media (min-width: 426px) and (max-width: 768px) {

  //   width: 85%;
  //   object-fit: fill!important;
  // }

  // Extra small (xs)
  @media (max-width: 575.98px) {
    width: 85%;
    object-fit: fill!important;
  }

  // Small (sm)
  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 85%;
    object-fit: fill!important;
  }

  // Medium (md)
  @media (min-width: 768px) and (max-width: 999.98px) {
    width: 90%;
    object-fit: fill!important;
  }

  // extra-Medium (md)
  @media (min-width: 1000px) and (max-width: 1199.98px) {
    width: 95%;
    object-fit: fill!important;
  }
  // Large (lg)
  @media (min-width: 1200px) and (max-width: 1500px) {
    width: 100%;
    object-fit: fill!important;
  }

  // Extra large (xl)
  @media (min-width: 1200px) {
  }

  // Extra extra large (xxl)
  @media (min-width: 1400px) {
  }
}

hr.solid {
  border-top: 3px solid #bbb;
}

.divider {
  @media (min-width: 800px) {
    width: 1px;
    background-color: rgba(94, 91, 91, 0.11);
    margin-left: 0px;
    margin-right: 0px;
    height: 5cm;
  }
}
.icon-header {
  margin-left: 5px;
  margin-top: -1px;
}

.typeform-question-container {
  min-height: 30vh;
  max-height: 80vh;
}

hr {
  margin-top: 1rem;
  margin-bottom: 0rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@import "@core/scss/vue/libs/vue-select.scss";

.filter-button-container {
  // flex: 1;
  // display: flex;
  margin-block: 1em;
  width: 100%;
  overflow-x: scroll;
  // -webkit-overflow-scrolling: touch;

  .filter-button-box {
    // display: flex;
    // min-width: 70em;
    margin-bottom: 6px;
    display: flex;
    // flex-wrap: wrap;
    // flex-direction: column;

    -webkit-overflow-scrolling: touch;

    .btn-icon {
      white-space: nowrap;
      margin: 0 0.5em;
    }
    .btn-icon:first-of-type {
      margin: 0 0.5em 0 0;
    }
    .btn-icon:last-of-type {
      margin: 0 0 0 0.5em;
    }
  }
}

.main-section {
  min-height: 80vh;
}

.very-bold {
  font-weight: 1000;
}
.less-bold {
  font-weight: 100;
}

.thick-separator {
  height: .5rem;
  width: 100%;
  background: $primary;
  margin: 2rem 0;
}

.bg-primary-gradient {
  background: $dark;
  background: linear-gradient(356deg, $dark 0%, lighten($dark, 10%) 90%);
  // background-image: linear-gradient(red, yellow);

}
.bg-main-background {
  background-image: url("~@/assets/images/pages/listings/background.svg");
  background-size: cover;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}
.slider-container {
  width: 20k;
  overflow-x: scroll;
  overflow-y: none;
}

.dot-primary {
  width: 8px;
  height: 8px;
  background-color: $primary;
  border-radius: 100%;
}
.dot-grey {
  width: 8px;
  height: 8px;
  background-color: $grey;
  border-radius: 100%;
}
.mobile-nav-dropdown {
  .btn {
    padding: 0;
  }
}
.description-container {
  max-width: 75ch;
  width: 100%;
}

 // Extra small (xs)
@media (max-width: 575.98px) {
}

// Small (sm)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium (md)
@media (min-width: 768px) and (max-width: 991.98px) {

  // .image-size {
  //   max-width: 100%;
  //   object-fit: scale-down;
  // }
}

.logo-space {
  @media (max-width: 425px) {
   margin-top: 0px!important;
  }

  @media (min-width: 426px) and (max-width: 800px) {
   margin-top: 60px!important;
  }

  @media (min-width: 801px) {
   margin-top: 100px!important;
  }

}

// Large (lg)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large (xl)
@media (min-width: 1200px) {
}

// Extra extra large (xxl)
@media (min-width: 1400px) {
}

body {
  background: #fff !important;
}

.listings-event {
  font-family: "DIN-Black";
}

.footer-social-media {
  padding: .5rem;
  border-radius: 100%;
  background-color: $white;
}

.event-item {
  max-width: 30rem;

  // Extra small (xs)
  @media (max-width: 575.98px) {
    max-width: 30rem;
  }

  // Small (sm)
  @media (min-width: 576px) and (max-width: 767.98px) {
    max-width: 24rem;
  }

  // Medium (md)
  @media (min-width: 768px) and (max-width: 991.98px) {
    max-width: 16rem;
  }

  // Large (lg)
  @media (min-width: 992px) and (max-width: 1199.98px) {
    max-width: 21rem;
  }

  // Extra large (xl)
  @media (min-width: 1200px) {
  }

  // Extra extra large (xxl)
  @media (min-width: 1400px) {
  }
}

</style>
